import Item from "./Item";

class MultiFoodGroupModel extends Item {
  constructor(i, x, maxH, maxW, minH, minW) {
    super(i, x, maxH, maxW, minH, minW);
    this.background = "#F6F6F6";
    this.backgroundList = "#FFFFFF";
    this.colorText = "#323232";
    this.colorTextName = "#323232";
    this.fontFamily = "Montserrat";
    this.fontFamilyName = "Montserrat";
    this.fontSizeTitle = "25";
    this.fontSizeFoodItem = "25";
    this.colorPrice = "#323232";
    this.fontFamilyPrice = "Montserrat";
    this.colorBackgroundIcon = "#F6F6F6";
    this.colorIcon = "#F6F6F6";
    this.colorLine = "#323232";
    this.image = "";
    this.text = "";
    this.multiFoodGroup = [
      {
        key: 0,
        id_foodgroup: "",
        food_items: [],
        res:"",
      },
      {
        key: 1,
        id_foodgroup: "",
        food_items: [],
        res:"",
      },
      {
        key: 2,
        id_foodgroup: "",
        food_items: [],
        res:"",
      },
      {
        key: 3,
        id_foodgroup: "",
        food_items: [],
        res:"",
      },
      {
        key: 4,
        id_foodgroup: "",
        food_items: [],
        res:"",
      },
      {
        key: 5,
        id_foodgroup: "",
        food_items: [],
        res:"",
      },
      {
        key: 6,
        id_foodgroup: "",
        food_items: [],
        res:"",
      },
      {
        key: 7,
        id_foodgroup: "",
        food_items: [],
        res:"",
      },
    ];
  }
}

export default MultiFoodGroupModel;
