import React from "react";
import { Button, Modal, Form, Col, Row, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import FontList from "../../styles/FontList";
import { getRegionByID, getValueLive } from "../../api/Api";
const listFont = FontList;

class FluidityLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      show_modify: false,
      color_bg: this.props.item.background,
      color_bg_canc: this.props.item.background,
      color_text: this.props.item.textColor,
      color_text_canc: this.props.item.textColor,
      fontfamily: this.props.item.textFont,
      fontfamily_canc: this.props.item.textFont,
      fontsize: this.props.item.textSize,
      fontsize_canc: this.props.item.textSize,
      switchbackground: true,
      zindex: this.props.item.zIndex,
      zindex_canc: this.props.item.zIndex,
      id_zone: this.props.item.zona,
      zone_valuelive: this.props.item.valueLive,
      capacity: this.props.item.capacity,
      line_color: "#d6d6d6",
      zone_name: this.props.item.zoneName,
      zone_validation: this.props.item.zoneValidation,
      Curved_tb: this.props.item.borderRadius,
      Curved_tb_canc: this.props.item.borderRadius,
    };
    this.handleChangeColorBg = this.handleChangeColorBg.bind(this);
    this.handleChangeColorText = this.handleChangeColorText.bind(this);
    this.handleChangeFontFamily = this.handleChangeFontFamily.bind(this);
    this.handleChangeFontSize = this.handleChangeFontSize.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleChangeIDZone = this.handleChangeIDZone.bind(this);
    this.handleChangeCurved = this.handleChangeCurved.bind(this);
  }

  componentDidMount() {
    this.confirmZone();
  }

  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
  };
  //Change Color Background
  handleChangeColorBg(event) {
    this.setState({ color_bg: event.target.value });
  }
  //Change Curved
  handleChangeCurved(event) {
        this.setState({ Curved_tb: event.target.value });

  }
  //Transparent
  handleswitchBackground = (event) => {
    this.setState({ switchbackground: event.target.checked });
    if (this.state.switchbackground === true) {
      this.setState({ color_bg: "transparent" });
    } else {
      this.setState({ color_bg: this.state.color_bg_canc });
    }
  };
  //Cahnge Color Text
  handleChangeColorText(event) {
    this.setState({ color_text: event.target.value });
  }
  //Cahnge Font Size
  handleChangeFontSize(event) {
    if (event.target.value > 28) {
      this.setState({ fontsize: 28 });
    } else if (event.target.value < 22) {
      this.setState({ fontsize: 22 });
    } else {
      this.setState({ fontsize: event.target.value });
    }
  }
  //Change Font Family
  handleChangeFontFamily(event) {
    this.setState({ fontfamily: event.target.value });
  }

  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex: 1 });
    } else {
      this.setState({ zindex: event.target.value });
    }
    this.props.onLayerChange(this.props.item.i, event.target.value);
  }
  //Change ID ZONE
  handleChangeIDZone(event) {
    this.setState({ id_zone: event.target.value });
  }

  //Cancel Changes
  cancelOnModal = () => {
    this.setState({ color_bg: this.state.color_bg_canc });
    this.setState({ zindex: this.state.zindex_canc });
    this.setState({ color_text: this.state.color_text_canc });
    this.setState({ fontfamily: this.state.fontfamily_canc });
    this.setState({ fontsize: this.state.fontsize_canc });
    this.setState({ Curved_tb: this.state.Curved_tb_canc });
    this.props.onLayerChange(this.props.item.i, this.state.zindex_bg_canc);
    this.handleCloseModify();
  };
  //Confirm Changes
  confirmChangeonModal = () => {
    this.setState({ color_bg_canc: this.state.color_bg });
    this.setState({ color_text_canc: this.state.color_text });
    this.setState({ fontfamily_canc: this.state.fontfamily });
    this.setState({ zindex_canc: this.state.zindex });
    this.setState({ fontsize_canc: this.state.fontsize });
    this.setState({ borderRadius_canc: this.state.borderRadius });
    this.props.item.background = this.state.color_bg;
    this.props.item.textColor = this.state.color_text;
    this.props.item.textFont = this.state.fontfamily;
    this.props.item.textSize = this.state.fontsize;
    this.props.item.zona = this.state.id_zone;
    this.props.item.zoneName = this.state.zone_name;
    this.props.item.zoneValidation = this.state.zone_validation;
    this.props.item.borderRadius = this.state.Curved_tb;
    this.props.onStyleChange(this.props.item);

    this.handleCloseModify();
  };

  //Get Region
  //getRegionByID
  confirmZone = () => {
    const id_zone = this.state.id_zone;

    getValueLive(id_zone).then((res) => {
      this.setState({ zone_valuelive: res[0].value_live });
     
      if (this.state.zone_valuelive == null) {
        this.setState({ zone_valuelive: 0 });
      }
    });

    getRegionByID(id_zone).then((res) => {
      if (res[0]) {
        this.setState({ zone_name: res[0].name });
        this.setState({ zone_validation: res[0].name });
        //this.confirmChangeonModal()
        this.setState({
          capacity: (100 / res[0].max_capacity) * this.state.zone_valuelive,
        });

        if (
          this.state.capacity < res[0].fluidity_conf.fluidity_triggers[1].level
        ) {
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[0].color,
          });
        } else if (
          this.state.capacity < res[0].fluidity_conf.fluidity_triggers[2].level
        ) {
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[1].color,
          });
        } else if (this.state.capacity < 100) {
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[2].color,
          });
        }

        this.setState({
          fluidity_triggers: res[0].fluidity_conf.fluidity_triggers,
        });
        this.setState({ fluidity_triggers: res[0].max_capacity });
      } else {
        this.setState({
          line_color: "#d6d6d6",
        });
        this.setState({ capacity: 100 });
        this.setState({ zone_name: "ZONE NAME" });
        this.setState({ zone_validation: "l'iD zone don't exist" });
      }
    });
  };
  borderRadiusContainer() {
    if (this.state.Curved_tb===undefined)
    {
      this.state.Curved_tb=0
     }
      let borderRadiusContainer = this.state.Curved_tb;
        return borderRadiusContainer;
  }
  render() {
    const divStyle = {
      overflow: "hidden",
      padding: "5px",
      width: "100%",
      height: "100%",
      fontFamily: this.state.fontfamily,
      color: this.state.color_text,
      fontSize: this.state.fontsize + "px",
      border: " 1px solid #1e1e338c",
      borderRadius: this.borderRadiusContainer() +"px",
    };
    const TextStyle = {
      color: this.state.color_text,
      fontSize: this.state.fontsize + "px",
      textAlign: "center",
      marginBottom: "0",
    };
    const lineStyle = {
      backgroundColor: this.state.color_bg,
      padding: "10px",
    };

    const ProgressStyle = {
      backgroundColor: this.state.line_color,
      width: this.state.capacity + "%",
    };
    return (
      <>
        <div className="container_component">
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>

          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div style={divStyle}>
            <h1 style={TextStyle}>{this.state.zone_name}</h1>
            <div style={lineStyle}>
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  aria-valuenow={this.state.capacity}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={ProgressStyle}
                ></div>
              </div>
            </div>
          </div>
        </div>

        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
           fade={false}
           animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Fluidity Line</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* Zone ID*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Zone Id</Form.Label>
                      <Col md={6}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.id_zone}
                            onChange={this.handleChangeIDZone}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.confirmZone}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          Zone:
                          <h6
                            className={
                              this.state.zone_validation ==
                                "l'iD zone don't exist" && "warning"
                            }
                          >
                            {this.state.zone_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* BACKGROUND COLOR */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Background</Form.Label>

                      <Col md={12} className="my-2">
                        <Form.Check
                          checked={this.state.switchbackground}
                          onChange={this.handleswitchBackground}
                          type="switch"
                          id="custom-switch"
                          label="Background Color"
                        />
                      </Col>

                      <Col md={6}>
                        <Form.Control
                          disabled={!this.state.switchbackground}
                          contentEditable
                          type="color"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />
                        <Form.Control
                          className="my-2"
                          disabled={!this.state.switchbackground}
                          type="text"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* FONTFAMILY */}
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamily}
                          value={this.state.fontfamily}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FONT SIZE */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontsize}
                          onChange={this.handleChangeFontSize}
                          min="20"
                          max="30"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontsize}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Color Text */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Text</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                   {/* Curved Edges*/}
                   <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Curved Edges Pixels</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.Curved_tb}
                          onChange={this.handleChangeCurved}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          Curved Edges Pixels: {this.state.Curved_tb}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.cancelOnModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.confirmChangeonModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Fluidity line</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button
                onClick={() => this.props.onRemoveItem(this.props.item.i)}
                variant="danger"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default FluidityLine;
