const Fontlist = [
  {
    name: "Helvetica Neue Bold",
    font: "Helvetica Neue Bold",
  },
  {
    name: "Helvetica Neue Light",
    font: "Helvetica Neue Light",
  },
  {
    name: "Helvetica Neue Regular",
    font: "Helvetica Neue Regular",
  },

  {
    name: "Caveat Regular",
    font: "Caveat Regular",
  },
  {
    name: "Caveat Bold",
    font: "Caveat Bold",
  },
  {
    name: "Caveat Light",
    font: "Caveat Light",
  },

  {
    name: "DMSerifText Regular",
    font: "DMSerifText Regular",
  },
  {
    name: "Dosis Regular",
    font: "Dosis Regular",
  },
  {
    name: "Dosis Bold",
    font: "Dosis Bold",
  },

  {
    name: "Dosis Light",
    font: "Dosis Light",
  },
  {
    name: "MarkScript",
    font: "MarkScript",
  },
  {
    name: "Montserrat Regular",
    font: "Montserrat Regular",
  },
  {
    name: "Montserrat Bold",
    font: "Montserrat Bold",
  },
  {
    name: "Montserrat Light",
    font: "Montserrat Light",
  },
  {
    name: "MPLUSRounded1c Regular",
    font: "MPLUSRounded1c Regular",
  },
  {
    name: "MPLUSRounded1c Bold",
    font: "MPLUSRounded1c Bold",
  },

  {
    name: "MPLUSRounded1c Light",
    font: "MPLUSRounded1c Light",
  },
  {
    name: "OpenSans Regular",
    font: "OpenSans Regular",
  },
  {
    name: "OpenSans Bold",
    font: "OpenSans Bold",
  },
  {
    name: "OpenSans Light",
    font: "OpenSans Light",
  },

  {
    name: "PermanentMarker ",
    font: "PermanentMarker ",
  },
  {
    name: "Roboto Regular",
    font: "Roboto Regular",
  },
  {
    name: "Roboto Bold",
    font: "Roboto Bold",
  },

  {
    name: "Roboto Light",
    font: "Roboto Light",
  },
  {
    name: "RobotoCondensed Regular",
    font: "RobotoCondensed Regular",
  },
  {
    name: "RobotoCondensed Bold",
    font: "RobotoCondensed Bold",
  },

  {
    name: "RobotoCondensed Light",
    font: "RobotoCondensed Light",
  },

  {
    name: "Arial Regular",
    font: "Arial Regular",
  },
  {
    name: "Arial Bold",
    font: "Arial Bold",
  },
  {
    name: "Arial Light",
    font: "Arial Light",
  },

  {
    name: "Kalam Regular",
    font: "Kalam Regular",
  },

  {
    name: "Kalam Bold",
    font: "Kalam Bold",
  },
  {
    name: "Kalam Light",
    font: "Kalam Light",
  },  {
    name: "Avenir Bold",
    font: "Avenir Bold",
  },
  {
    name: "Avenir Regular",
    font: "Avenir Regular",
  },
  {
    name: "Avenir Light",
    font: "Avenir Light",
  },
  {
    name: "CoveredByYourGrace Regular",
    font: "CoveredByYourGrace Regular",
  },
];

Fontlist.sort(function (a, b) {
  var textA = a.name;
  var textB = b.name;
  return textA < textB ? -1 : textA > textB ? 1 : 0;
});

export default Fontlist;
