import React from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";

class VerticalLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      show_modify: false,
      color_bg: this.props.item.color,
      color_bg_canc: this.props.item.color,
      zindex_bg: this.props.item.zIndex,
      zindex_bg_canc: this.props.item.zIndex,
    };
    this.handleChangeColor = this.handleChangeColor.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
  }
  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
  };
  //Change Color
  handleChangeColor(event) {
    this.setState({ color_bg: event.target.value });
  }

  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex_bg: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex_bg: 1 });
    } else {
      this.setState({ zindex_bg: event.target.value });
    }
    this.props.onLayerChange(this.props.item.i, event.target.value);
  }

  //Cancel Changes
  cancelOnModal = () => {
    this.setState({ color_bg: this.state.color_bg_canc });
    this.setState({ zindex_bg: this.state.zindex_bg_canc });
    this.props.onLayerChange(this.props.item.i, this.state.zindex_bg_canc);
    this.handleCloseModify();
  };
  //Confirm Changes
  confirmChangeonModal = () => {
    this.setState({ color_bg_canc: this.state.color_bg });
    this.setState({ zindex_bg_canc: this.state.zindex_bg });

    this.props.item.color = this.state.color_bg;

    this.props.onStyleChange(this.props.item);

    this.handleCloseModify();
  };

  render() {
    const divStyle = {
      margin: "0",
      border: "2px solid",
      borderColor: this.state.color_bg,
      width: "0",
      height: "100%",
    };

    return (
      <>
        <div className="container_component">
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div style={divStyle}></div>
        </div>

        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
           fade={false}
           animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Vertical Line</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColor}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColor}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex_bg}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.cancelOnModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.confirmChangeonModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Vertical Line</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button
                onClick={() => this.props.onRemoveItem(this.props.item.i)}
                variant="danger"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default VerticalLine;
