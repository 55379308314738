import React from "react";
import {
    Button,
    Form,
    Image,
    Col,
    Row
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import thumb from "../../../image/noImage.png";
import { uploadFile } from "../../../api/Api";
import { getFileExtension } from "../../../utils/Utils";

class UploadImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image: this.props.imageFromParent,
            image_canc: this.props.imageFromParent,
            previewImage: thumb,
            disabled: false ,
            imageFile: new File([], "")
        };
        this.handleChangeImage = this.handleChangeImage.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.headerImage !== this.props.headerImage) {
            this.setState({ image: this.props.headerImage })

        }
    }

    //Change Icon
    handleChangeImage(event) {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                this.setState({ disabled: true });
               this.setState({ image: reader.result });

                this.uploadImage();
            }
        };
        reader.readAsDataURL(event.target.files[0]);
        this.setState({ imageFile: event.target.files[0] });
    }
    //DELETE IMAGE
    handleDeleteImage = () => {
        this.setState({ image: "" });
        this.props.imageUploadedToParent("");  
    };

    //UPLOAD IMAGE
    uploadImage = () => {
        if (getFileExtension(this.state.imageFile.name) === "svg") {
            this.state.imageFile
                .text()
                .then((response) => {
                    let str = response.replace(
                        /<style\b[^<>]*>[\s\S]*?<\/style\s*>/gi,
                        ""
                    );
                    this.props.imageUploadedToParent(str);
                    this.setState({ image: str });
                    this.setState({ disabled: false });
                })
        } else {
            uploadFile(this.state.imageFile)
                .then((response) => {
                    this.setState({ image: response.location });
                    this.props.imageUploadedToParent(response.location);
                    this.setState({ disabled: false });
                })
        }
    };



    render() {
      
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator

        return (
            <>
                <Row>
                    <Col md={10}>
                        <Form.Group>
                            <Form.Label>Icon</Form.Label>
                            <Col md={6}>
                                <div  className="resizeSVG">
                                    {!pattern.test(this.state.image) && (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: this.state.image,
                                            }}
                                        />
                                    )}
                                    {!!pattern.test(this.state.image) && (
                                        <img src={this.state.image} width="100%" alt="" />
                                    )}
                                    {this.state.image === "" && (
                                        <Image src={thumb} thumbnail />
                                    )}

                                    {this.state.image !== "" && this.state.image != null && (
                                        <Button
                                            className="circlebutton"
                                            variant="danger"
                                            onClick={this.handleDeleteImage}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                    )}
                                </div>
                                <Form.File
                                    accept="image/*"
                                    className={"mt-2"}
                                    onChange={this.handleChangeImage}
                                    disabled={this.state.disabledFromId}
                                />

                                 {this.state.disabled === true && (
                                    <p>Loading Image...</p>
                                )} 
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
            </>
        );
    }
}

export default UploadImage;
