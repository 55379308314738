import React from "react";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Modal,
  Form,
  Col,
  Row,
  InputGroup,
  Image,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import FontList from "../../styles/FontList";
import { getRegionByID, getValueLive } from "../../api/Api";
import thumb from "../../image/noImage.png";
import thumbHorizontal from "../../image/horizontal_thumb.png";

import { uploadFile } from "../../api/Api";
const listFont = FontList;

class FluidityRectangle_Port extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      show_modify: false,
      color_bg: this.props.item.background,
      color_bg_canc: this.props.item.background,
      color_text: this.props.item.textColor,
      color_text_canc: this.props.item.textColor,
      fontfamily: this.props.item.textFont,
      fontfamily_canc: this.props.item.textFont,
      fontsize: this.props.item.textSize,
      fontsize_canc: this.props.item.textSize,
      switchbackground: true,
      width_size: this.props.item.widhtSize,
      height_size: this.props.item.heightSize,
      zindex: this.props.item.zIndex,
      zindex_canc: this.props.item.zIndex,
      id_zone: this.props.item.zona,
      zone_valuelive: "",
      capacity: "100",
      line_color: "#d6d6d6",
      zone_name: "ZONE NAME",
      fluidity_text: "",
      zone_validation: "",
      image: this.props.item.image,
      image_canc: this.props.item.image,
      imageFile: new File([], ""),
      disabled: false,
      Curved_tb: this.props.item.borderRadius,
      Curved_tb_canc: this.props.item.borderRadius,
    };
    this.handleChangeImage = this.handleChangeImage.bind(this);
    this.handleChangeColorBg = this.handleChangeColorBg.bind(this);
    this.handleChangeColorText = this.handleChangeColorText.bind(this);
    this.handleChangeFontFamily = this.handleChangeFontFamily.bind(this);
    this.handleChangeFontSize = this.handleChangeFontSize.bind(this);
    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleChangeIDZone = this.handleChangeIDZone.bind(this);
    this.handleChangeCurved = this.handleChangeCurved.bind(this);

  }

  componentDidMount() {
    this.confirmZone();
  }

  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
  };
  //Change Image
  handleChangeImage(event) {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ disabled: true });
        this.setState({ image: reader.result });
        this.uploadImage();
      }
    };
    reader.readAsDataURL(event.target.files[0]);
    this.setState({ imageFile: event.target.files[0] });
  }
  handleDeleteImage = () => {
    this.setState({ image: "" });
  };

  //Cahnge SIZE
  changeSize_1 = () => {
    this.setState({ height_size: 78 });
    this.setState({ width_size: 103 });

    this.props.item.widhtSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;

    this.confirmChangeonModal();
    this.props.onFluidityRectangleSizeChange(this.props.item, 102, 78);
  };
  changeSize_2 = () => {
    this.setState({ height_size: 47 });
    this.setState({ width_size: 103 });

    this.props.item.widhtSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;
    this.confirmChangeonModal();
    this.props.onFluidityRectangleSizeChange(this.props.item, 102, 47);
  };
  changeSize_3 = () => {
    this.setState({ height_size: 80 });
    this.setState({ width_size: 50 });

    this.props.item.widhtSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;

    this.confirmChangeonModal();
    this.props.onFluidityRectangleSizeChange(this.props.item, 50, 80);
  };

  //Cahnge Color Background
  handleChangeColorBg(event) {
    this.setState({ color_bg: event.target.value });
  }

  //Cahnge Color Text
  handleChangeColorText(event) {
    this.setState({ color_text: event.target.value });
  }
  //Cahnge Font Size
  handleChangeFontSize(event) {
    if (event.target.value > 52) {
      this.setState({ fontsize: 52 });
    } else if (event.target.value < 38) {
      this.setState({ fontsize: 38 });
    } else {
      this.setState({ fontsize: event.target.value });
    }
  }
  //Change Font Family
  handleChangeFontFamily(event) {
    this.setState({ fontfamily: event.target.value });
  }
    //Change Curved
    handleChangeCurved(event) {
      this.setState({ Curved_tb: event.target.value });

  }
  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex: 1 });
    } else {
      this.setState({ zindex: event.target.value });
    }
    this.props.onLayerChange(this.props.item.i, event.target.value);
  }
  //Change ID ZONE
  handleChangeIDZone(event) {
    this.setState({ id_zone: event.target.value });
  }

  //Cancel Changes
  cancelOnModal = () => {
    this.setState({ image: this.state.image_canc });
    this.setState({ color_bg: this.state.color_bg_canc });
    this.setState({ zindex: this.state.zindex_canc });
    this.setState({ color_text: this.state.color_text_canc });
    this.setState({ fontfamily: this.state.fontfamily_canc });
    this.setState({ Curved_tb: this.state.Curved_tb_canc });
    this.props.onLayerChange(this.props.item.i, this.state.zindex_canc);
    this.handleCloseModify();
  };
  //Confirm Changes
  confirmChangeonModal = () => {
    this.setState({ image_canc: this.state.image });
    this.setState({ color_bg_canc: this.state.color_bg });
    this.setState({ color_text_canc: this.state.color_text });
    this.setState({ fontfamily_canc: this.state.fontfamily });
    this.setState({ zindex_canc: this.state.zindex });
    this.setState({ borderRadius_canc: this.state.borderRadius });
    this.props.item.image = this.state.image;
    this.props.item.background = this.state.color_bg;
    this.props.item.textColor = this.state.color_text;
    this.props.item.textFont = this.state.fontfamily;
    this.props.item.zona = this.state.id_zone;
    this.props.item.textSize = this.state.fontsize;
    this.props.item.widthSize = this.state.width_size;
    this.props.item.heightSize = this.state.height_size;
    this.props.item.borderRadius = this.state.Curved_tb;
    this.props.onStyleChange(this.props.item);

    this.handleCloseModify();
  };
  //UPLOAD IMAGE
  uploadImage = () => {
    let obj = {};
    uploadFile(this.state.imageFile)
      .then((response) => {
        this.setState({ image: response.location });
      })
      .then(() => {
        this.props.item.backgroundImage =
          this.state.image && this.setState({ disabled: false });
      });
  };

  //Get Region
  //getRegionByID
  confirmZone = () => {
    const id_zone = this.state.id_zone;

    getValueLive(id_zone).then((res) => {
      this.setState({ zone_valuelive: res[0].value_live });

      if (this.state.zone_valuelive == null) {
        this.setState({ zone_valuelive: 0 });
      }
    });
    this.setState({ line_color: "#d6d6d6" });
    this.setState({ capacity: 0 });
    this.setState({ zone_name: "ZONE NAME" });
    this.setState({ fluidity_text: "" });
    getRegionByID(id_zone).then((res) => {
      if (res[0]) {
        this.setState({ zone_name: res[0].name });
        this.setState({ zone_validation: res[0].name });

        this.setState({
          capacity: (100 / res[0].max_capacity) * this.state.zone_valuelive,
        });

        if (
          this.state.capacity < res[0].fluidity_conf.fluidity_triggers[1].level
        ) {
          this.setState({
            fluidity_text: res[0].fluidity_conf.fluidity_triggers[0].text,
          });
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[0].color,
          });
        } else if (
          this.state.capacity < res[0].fluidity_conf.fluidity_triggers[2].level
        ) {
          this.setState({
            fluidity_text: res[0].fluidity_conf.fluidity_triggers[1].text,
          });
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[1].color,
          });
        } else if (this.state.capacity < 100) {
          this.setState({
            fluidity_text: res[0].fluidity_conf.fluidity_triggers[2].text,
          });
          this.setState({
            line_color: res[0].fluidity_conf.fluidity_triggers[2].color,
          });
        } else {
          this.setState({
            fluidity_triggers: res[0].fluidity_conf.fluidity_triggers,
          });
          this.setState({ fluidity_triggers: res[0].max_capacity });
        }
      } else {
        this.setState({ line_color: "#d6d6d6" });
        this.setState({ capacity: 0 });
        this.setState({ zone_name: "ZONE NAME" });
        this.setState({ fluidity_text: "" });
        this.setState({ zone_validation: "l'iD zone don't exist" });
      }
    });
  };
  borderRadiusContainer() {
    if (this.state.Curved_tb===undefined)
    {
      this.state.Curved_tb=0
     }
      let borderRadiusContainer = this.state.Curved_tb;
        return borderRadiusContainer;
  }

  render() {
    const divStyle = {
      overflow: "hidden",
      padding: "15px 0",
      backgroundColor: this.state.color_bg,
      width: this.state.width_size * 10 + "px",
      height: this.state.height_size * 10 + "px",
      fontFamily: this.state.fontfamily,
      color: this.state.color_text,
      fontSize: this.state.fontsize + "px",
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      display: "flex",
      flexDirection: "column",
      borderRadius: this.borderRadiusContainer() +"px",
    };
    const TextStyle = {
      fill: this.state.color_text,
      fontSize: this.state.fontsize + "px",
    };
    const imageStyle = {
      display: "flex",
       minHeight: "60%",
       flex:"1",
      width: "100%",
      objectFit: "cover",
      backgroundColor: "#d6d6d6",
    };
    const ProgressStyle = {
      backgroundColor: this.state.line_color,
      width: this.state.capacity + "%",
    };
    const ProgressContainer = {
      height: this.state.height_size === 80 ? "150px" : "120px",
      maxHeight: this.state.height_size === 80 ? "150px" : "120px",
      minHeight: this.state.height_size === 80 ? "150px" : "120px",
    };

    const headStyle = {
      display: "flex",
      alignItems: " flex-start",
      justifyContent: "space-between",
      padding: "5px 20px",
      // height: "80px",
      // maxHeight: "80px",
       minHeight: "80px",
    };

    const CircleStyle = {
      backgroundColor: this.state.line_color,
    };
    return (
      <>
        <div>
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>

          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div style={divStyle}>
            <div style={headStyle}>
              <h1 style={TextStyle}>
                <strong>{this.state.zone_name}</strong>
              </h1>
              <h1 style={TextStyle}>{this.state.fluidity_text}</h1>
            </div>
            <Image
              src={this.state.image === "" ? thumbHorizontal : this.state.image}
              style={imageStyle}
            />
            <div    style={ProgressContainer}
            className="progress_container">
              <div className="progress progress-rectangle">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={this.state.capacity}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={ProgressStyle}
                ></div>
              </div>

              <div className="circle-fluidity" style={CircleStyle}></div>
            </div>
          </div>
        </div>

        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            fade={false}
            animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Fluidity Rectangle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* SELECT SIZE*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Select Size</Form.Label>
                      <Col>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                          <ButtonGroup
                            className="mr-2"
                            aria-label="First group"
                          >
                            <Button onClick={this.changeSize_1}>1</Button>
                            <Button onClick={this.changeSize_2}>2</Button>
                            <Button onClick={this.changeSize_3}>3</Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Zone ID*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Zone Id</Form.Label>
                      <Col md={6}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.id_zone}
                            onChange={this.handleChangeIDZone}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.confirmZone}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          Zone:
                          <h6
                            className={
                              this.state.zone_validation ===
                              "l'iD zone don't exist" && "warning"
                            }
                          >
                            {this.state.zone_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* IMAGE*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Image</Form.Label>
                      <Col md={6}>
                        <Image
                          src={
                            this.state.image === "" ? thumb : this.state.image
                          }
                          thumbnail
                        />
                        {this.state.image !== "" && (
                          <Button
                            className="circlebutton"
                            variant="danger"
                            onClick={this.handleDeleteImage}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </Button>
                        )}
                        <Form.File
                          accept="image/png, image/jpeg"
                          className={"mt-2"}
                          onChange={this.handleChangeImage}
                        />

                        {this.state.disabled === true && (
                          <p>Loading Image...</p>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* BACKGROUND COLOR */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Background</Form.Label>

                      <Col md={6}>
                        <Form.Control
                          contentEditable
                          type="color"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* FONTFAMILY */}
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamily}
                          value={this.state.fontfamily}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FONT SIZE */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font size</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontsize}
                          onChange={this.handleChangeFontSize}
                          min="38"
                          max="52"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontsize}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Color Text */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Text</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Curved Edges*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Curved Edges Pixels</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.Curved_tb}
                          onChange={this.handleChangeCurved}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          Curved Edges Pixels: {this.state.Curved_tb}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={this.cancelOnModal}
                disabled={this.state.disabled}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={this.confirmChangeonModal}
                disabled={this.state.disabled}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete background</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button
                onClick={() => this.props.onRemoveItem(this.props.item.i)}
                variant="danger"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default FluidityRectangle_Port;
