import React from "react";

class Bar extends React.Component {
  borderRadiusContainer() {
    if (this.props.item.borderRadius===undefined)
      {
        this.props.item.borderRadius=0
       }
      let borderRadiusContainer = this.props.item.borderRadius
        return borderRadiusContainer;
  }

  render() {
    const divStyle = {
     backgroundColor: this.props.item.background,
      width: "100%",
      height: "100%",
      opacity:this.props.item.alpha,
      boxShadow: "5px 10px 30px rgba(0,0,0,.2)",
      borderRadius:this.borderRadiusContainer() +"px",
    };
    return (
      <>
         <div className="container_component">
          <div style={divStyle}></div>
        </div>
      </>
    );
  }
}

export default Bar;
