import React from "react";
import { Button, Modal, Form, Col, Row, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getFoodGroupByID, getfooditemsbyfoodgroupID } from "../../api/Api";
import { getFileExtension } from "../../utils/Utils";
import FontList from "../../styles/FontList";

const listFont = FontList;

class MultiFoodGroup_Land extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_delete: false,
      show_modify: false,
      zindex_tb: this.props.item.zIndex,
      zindex_tb_canc: this.props.item.zIndex,
      color_bgicon: this.props.item.colorBackgroundIcon,
      color_bgicon_canc: this.props.item.colorBackgroundIcon,
      color_icon: this.props.item.colorIcon,
      color_icon_canc: this.props.item.colorIcon,
      color_bg: this.props.item.background,
      color_bg_canc: this.props.item.background,
      color_listBg: this.props.item.backgroundList,
      color_listBg_canc: this.props.item.backgroundList,
      color_text: this.props.item.colorText,
      color_text_canc: this.props.item.colorText,
      color_textName: this.props.item.colorTextName,
      color_textName_canc: this.props.item.colorTextName,
      fontsizeFoodItem: this.props.item.fontSizeFoodItem,
      fontsizeFoodItem_canc: this.props.item.fontSizeFoodItem,
      fontfamilyName: this.props.item.fontFamilyName,
      fontfamilyName_canc: this.props.item.fontFamilyName,
      color_textPrice: this.props.item.colorPrice,
      color_textPrice_canc: this.props.item.colorPrice,
      fontfamilyPrice: this.props.item.fontFamilyPrice,
      fontfamilyPrice_canc: this.props.fontFamilyPrice,
      color_line: this.props.item.colorLine,
      color_line_canc: this.props.item.colorLine,

      Curved_tb: this.props.item.borderRadius,
      Curved_tb_canc: this.props.item.borderRadius,

      image: this.props.item.image,
      image_canc: this.props.item.image,
      text: this.props.item.text,
      text_canc: this.props.item.text,
      fontfamily: this.props.item.fontFamily,
      fontfamily_canc: this.props.item.fontFamily,
      fontsize: this.props.item.fontSizeTitle,
      fontsize_canc: this.props.item.fontSizeTitle,
      multiFoodGroup: this.props.item.multiFoodGroup,
      multiFoodGroup_items: [ {
        key: 0,
        id_foodgroup: this.props.item.multiFoodGroup[0].id_foodgroup,
        title_foodgroup: "",
        res: "",
        foodgroup_validation: "",
        icon: "",
        food_items: [],
      },
      {
        key: 1,
        id_foodgroup: this.props.item.multiFoodGroup[1].id_foodgroup,
        title_foodgroup: "",
        res: "",
        foodgroup_validation: "",
        icon: "",
        food_items: [],
      },
      {
        key: 2,
        id_foodgroup: this.props.item.multiFoodGroup[2].id_foodgroup,
        title_foodgroup: "",
        res: "",
        foodgroup_validation: "",
        icon: "",
        food_items: [],
      },
      {
        key: 3,
        id_foodgroup: this.props.item.multiFoodGroup[3].id_foodgroup,
        title_foodgroup: "",
        res: "",
        foodgroup_validation: "",
        icon: "",
        food_items: [],
      },
      {
        key: 4,
        id_foodgroup: this.props.item.multiFoodGroup[4].id_foodgroup,
        title_foodgroup: "",
        res: "",
        foodgroup_validation: "",
        icon: "",
        food_items: [],
      },
      {
        key: 5,
        id_foodgroup: this.props.item.multiFoodGroup[5].id_foodgroup,
        title_foodgroup: "",
        res: "",
        foodgroup_validation: "",
        icon: "",
        food_items: [],
      },
      {
        key: 6,
        id_foodgroup: this.props.item.multiFoodGroup[6].id_foodgroup,
        title_foodgroup: "",
        res: "",
        foodgroup_validation: "",
        icon: "",
        food_items: [],
      },
      {
        key: 7,
        id_foodgroup: this.props.item.multiFoodGroup[7].id_foodgroup,
        title_foodgroup: "",
        res: "",
        foodgroup_validation: "",
        icon: "",
        food_items: [],
      },
    ],
    };

    this.handleChangeIndex = this.handleChangeIndex.bind(this);
    this.handleChangeColorBgIcon = this.handleChangeColorBgIcon.bind(this);
    this.handleChangeColorBg = this.handleChangeColorBg.bind(this);
    this.handleChangeColorListBg = this.handleChangeColorListBg.bind(this);
    this.handleChangeColorText = this.handleChangeColorText.bind(this);
    this.handleChangeColorIcon = this.handleChangeColorIcon.bind(this);
    this.handleChangeFontSize = this.handleChangeFontSize.bind(this);
    this.handleChangeFontFamily = this.handleChangeFontFamily.bind(this);
    this.handleChangeColorTextName = this.handleChangeColorTextName.bind(this);
    this.handleChangeCurved = this.handleChangeCurved.bind(this);
    this.handleChangeFontSizeFoodItem = this.handleChangeFontSizeFoodItem.bind(
      this
    );
    this.handleChangeFontFamilyName = this.handleChangeFontFamilyName.bind(
      this
    );
    this.handleChangeColorTextPrice = this.handleChangeColorTextPrice.bind(
      this
    );
    this.handleChangeFontFamilyPrice = this.handleChangeFontFamilyPrice.bind(
      this
    );
    this.handleChangeColorLine = this.handleChangeColorLine.bind(this);

    this.handleChangeIDFoodgroup = this.handleChangeIDFoodgroup.bind(this);
  }

  componentDidMount() {
    this.props.item.multiFoodGroup.map((value) => this.getFoodItems(value.key));
    this.MultiFoodGroup = this.props.item.multiFoodGroup.map((value) =>
      this.getFoodItems(value.key)
    );
    
  }
  //MODAL DELETE
  //Close
  handleCloseDelete = () => {
    this.setState({ show_delete: false });
  };
  //Open
  handleShowDelete = () => {
    this.setState({ show_delete: true });
  };
  //MODAL MODIFY
  //Close
  handleCloseModify = () => {
    this.setState({ show_modify: false });
  };
  //Open
  handleShowModify = () => {
    this.setState({ show_modify: true });
  };
  //Change Color Background Bar
  handleChangeColorBg(event) {
    this.setState({ color_bg: event.target.value });
  }
  //Change Color Background List
  handleChangeColorListBg(event) {
    this.setState({ color_listBg: event.target.value });
  }
  //Change Color background Icon
  handleChangeColorBgIcon(event) {
    this.setState({ color_bgicon: event.target.value });
  }

  //Change Color  Icon
  handleChangeColorIcon(event) {
    this.setState({ color_icon: event.target.value });
  }
  //Change Color Title
  handleChangeColorText(event) {
    this.setState({ color_text: event.target.value });
  }
  //Change Text Title
  handleChangeTextTitle(event) {
    this.setState({ text: event.target.value });
  }
  //Change Font Family Title
  handleChangeFontFamily(event) {
    this.setState({ fontfamily: event.target.value });
  }
  //Change FONT SIZE Title
  handleChangeFontSize(event) {
    if (event.target.value > 35) {
      this.setState({ fontsize: 35 });
    } else if (event.target.value < 20) {
      this.setState({ fontsize: 20 });
    } else {
      this.setState({ fontsize: event.target.value });
    }
  }

  //Change FONT SIZE Food Item
  handleChangeFontSizeFoodItem(event) {
    if (event.target.value > 35) {
      this.setState({ fontsizeFoodItem: 35 });
    } else if (event.target.value < 20) {
      this.setState({ fontsizeFoodItem: 20 });
    } else {
      this.setState({ fontsizeFoodItem: event.target.value });
    }
  }

  //Change Color Name Food Item
  handleChangeColorTextName(event) {
    this.setState({ color_textName: event.target.value });
  }

  //Change Font Family NAME Food Item
  handleChangeFontFamilyName(event) {
    this.setState({ fontfamilyName: event.target.value });
  }

  //Change Color Price
  handleChangeColorTextPrice(event) {
    this.setState({ color_textPrice: event.target.value });
  }
  //Change Font Family Price Food Item
  handleChangeFontFamilyPrice(event) {
    this.setState({ fontfamilyPrice: event.target.value });
  }

  //Change Font Family Price Food Item
  // handleChangeFontFamilyPrice(event) {
  //   this.setState({ fontfamilyPrice: event.target.value });
  // }
  //Change Color LINE
  handleChangeColorLine(event) {
    this.setState({ color_line: event.target.value });
  }
    //Change Curved
    handleChangeCurved(event) {
      this.setState({ Curved_tb: event.target.value });

}
  //Change Index
  handleChangeIndex(event) {
    if (event.target.value > 999) {
      this.setState({ zindex_tb: 999 });
    } else if (event.target.value < 1) {
      this.setState({ zindex_tb: 1 });
    } else {
      this.setState({ zindex_tb: event.target.value });
    }
    //  this.props.onLayerChange(this.props.item.i, event.target.value);
  }

  //Cancel Changes
  cancelOnModal = () => {
    this.setState({ color_bgicon: this.state.color_bgicon_canc });
    this.setState({ color_icon: this.state.color_icon_canc });
    this.setState({ color_text: this.state.color_text_canc });
    this.setState({ color_bg: this.state.color_bg_canc });
    this.setState({ color_listBg: this.state.color_listBg_canc });
    this.setState({ text: this.state.text_canc });
    this.setState({ zindex_tb: this.state.zindex_tb_canc });
    this.setState({ image: this.state.image_canc });
    this.setState({ fontfamily: this.state.fontfamily_canc });
    this.setState({ color_textName: this.state.color_textName_canc });
    this.setState({ fontsizeFoodItem: this.state.fontsizeFoodItem_canc });
    this.setState({ fontfamilyName: this.state.fontfamilyName_canc });
    this.setState({ color_textPrice: this.state.color_textPrice_canc });
    this.setState({ fontfamilyPrice: this.state.fontfamilyPrice_canc });
    this.setState({ color_line: this.state.color_line_canc });
    this.setState({ Curved_tb: this.state.Curved_tb_canc });
    //  this.props.onLayerChange(this.props.item.i, this.state.zindex_tb_canc);
    this.handleCloseModify();
  };
  //Confirm Changes
  confirmChangeonModal = () => {
    this.setState({ color_bgicon_canc: this.state.color_bgicon });
    this.setState({ color_icon_canc: this.state.color_icon });
    this.setState({ color_bg_canc: this.state.color_bg });
    this.setState({ color_listBg_canc: this.state.color_listBg });
    this.setState({ color_text_canc: this.state.color_text });
    this.setState({ text_canc: this.state.text });
    this.setState({ zindex_tb_canc: this.state.zindex_tb });
    this.setState({ image_canc: this.state.image });
    this.setState({ fontfamily_canc: this.state.fontfamily });
    this.setState({ color_textName_canc: this.state.color_textName });
    this.setState({ fontsizeFoodItem_canc: this.state.fontsizeFoodItem });
    this.setState({ fontfamilyName_canc: this.state.fontfamilyName });
    this.setState({ color_textPrice_canc: this.state.color_textPrice });
    this.setState({ fontfamilyPrice_canc: this.state.fontfamilyPrice });
    this.setState({ color_line_canc: this.state.color_line });
    this.setState({ borderRadius_canc: this.state.borderRadius });
    this.props.item.background = this.state.color_bg;
    this.props.item.backgroundList = this.state.color_listBg;
    this.props.item.colorText = this.state.color_text;
    this.props.item.colorTextName = this.state.color_textName;
    this.props.item.fontFamily = this.state.fontfamily;
    this.props.item.fontFamilyName = this.state.fontfamilyName;
    this.props.item.fontSizeTitle = this.state.fontsize;
    this.props.item.fontSizeFoodItem = this.state.fontsizeFoodItem;
    this.props.item.colorPrice = this.state.color_textPrice;
    this.props.item.fontFamilyPrice = this.state.fontfamilyPrice;
    this.props.item.colorBackgroundIcon = this.state.color_bgicon;
    this.props.item.colorIcon = this.state.color_icon;
    this.props.item.colorLine = this.state.color_line;
    this.props.item.image = this.state.image;
    this.props.item.text = this.state.text;
    this.props.item.multiFoodGroup = this.state.multiFoodGroup;
    this.props.item.borderRadius = this.state.Curved_tb;
    this.props.onStyleChange(this.props.item);

    this.handleCloseModify();
  };

  //Handle ID FOODGROUP
  handleChangeIDFoodgroup(event, keyId) {
    let key = keyId;
    this.setState((prevState) => ({
      multiFoodGroup: prevState.multiFoodGroup.map((el) =>
        el.key === key ? { ...el, id_foodgroup: event.target.value } : el
      ),
    }));
    this.setState((prevState) => ({
      multiFoodGroup_items: prevState.multiFoodGroup_items.map((el) =>
        el.key === key ? { ...el, id_foodgroup: event.target.value } : el
      ),
    }));
  }

  //get FOOD Item FROM FOOD GROUPID & FOOD GROUP INFO
  getFoodItems(keyId) {
    let key = keyId;
    
       this.setState((prevState) => ({
        multiFoodGroup_items: prevState.multiFoodGroup_items.map((el) =>
          el.key === key
            ? {
                ...el,
                food_items: "",
                res: "",
                foodgroup_validation: "",
              }
            : el
        ),
         }));
     
   

    //  FOOD GROUP INFO
    getFoodGroupByID(this.state.multiFoodGroup[key].id_foodgroup).then(
      (res) => {
        if (res == null) {
          this.setState((prevState) => ({
            multiFoodGroup_items: prevState.multiFoodGroup_items.map((el) =>
              el.key === key
                ? {
                    ...el,
                    res: "",
                    foodgroup_validation: "Food Group empty",
                  }
                : el
            ),
          }));
        } else if (res) {
          this.setState((prevState) => ({
            multiFoodGroup_items: prevState.multiFoodGroup_items.map((el) =>
              el.key === key
                ? { ...el, res: res, foodgroup_validation: res.name }
                : el
            ),
          }));
        } else {
          this.setState((prevState) => ({
            multiFoodGroup_items: prevState.multiFoodGroup_items.map((el) =>
              el.key === key
                ? {
                    ...el,
                    res: "",
                    foodgroup_validation: "l'iD food group don't exist",
                  }
                : el
            ),
          }));
        }
      }
    );
    //get FOOD Item FROM FOOD GROUPID
    getfooditemsbyfoodgroupID(this.state.multiFoodGroup[key].id_foodgroup).then(
      (res) => {
        if (res) {
          this.setState((prevState) => ({
            multiFoodGroup_items: prevState.multiFoodGroup_items.map((el) =>
              el.key === key
                ? {
                    ...el,
                    food_items: res,
                  }
                : el
            ),
          }));
        }
      }
    );
  }

  svgRender(svgPicture) {
    if (svgPicture) {
      let svgClear = svgPicture.replace(/\\/g, "").replace("<style>", "");

      return (
        <div
          className="svgTitle"
          dangerouslySetInnerHTML={{ __html: svgClear }}
        />
      );
    }
  }

  getmaxFoodItem() {
    let numberFoodGroup = this.state.multiFoodGroup_items.filter(function (value) {
      return value.id_foodgroup !== "";
    });
    if (
      numberFoodGroup.filter(function (value) {
        return value.id_foodgroup !== "" && value.food_items.length > 0;
      }).length > 4
    ) {
      return 4;
    } else {
      return 8;
    }
  }
  //Capitalize
  capitalize(s) {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
  borderRadiusContainer() {
    if (this.state.Curved_tb===undefined)
    {
      this.state.Curved_tb=0
     }
      let borderRadiusContainer = this.state.Curved_tb;
        return borderRadiusContainer;
  }
  render() {
    const divStyle = {
      height: "100%",
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignContent: "baseline",
      justifyContent: "center",
      borderRadius: this.borderRadiusContainer() +"px",
    };

    const foodGroupStyle = {
      marginTop: " -30px",
      paddingTop: "40px",
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      backgroundColor: this.state.color_listBg,
      borderRadius:this.borderRadiusContainer() +"px",
    };
    const containerStyle = {
      display: "block",
      minWidth: "22%",
      maxWidth: "50%",
      flex: "1 1 21%",
      maxHeight: "100%",
      margin: "40px 15px",
      borderRadius:this.borderRadiusContainer() +"px",
    };
    const titlebarStyle = {
      boxShadow: " 5px 10px 30px rgba(0,0,0,.2)",
      width: "min-content",
      maxWidth: "500px",
      height: "60px",
      backgroundColor: " #fff",
      display: " inline-flex",
      borderRadius:this.borderRadiusContainer() +"px",
    };

    const iconContainer = {
      padding: "6px",
      minWidth: "60px",
      maxWidth: "60px",
      backgroundColor: this.state.color_bgicon,
      fill: this.state.color_icon,
    };

    const titleStyle = {
      width: "min-content",
      maxWidth: "500px",
      color: this.state.color_text,
      backgroundColor: this.state.color_bg,
      fontSize: this.state.fontsize + "px",
      display: "block",
      justifyContent: "center",
      fontFamily: this.state.fontfamily,
    };

    const rowItemStyle = {
      margin: " 5px 20px",
      display: "flex",
      placeContent: " space-between",
      borderBottomColor: this.state.color_line,
    };

    const nameStyle = {
      color: this.state.color_textName,
      fontSize: this.state.fontsizeFoodItem + "px",
      fontFamily: this.state.fontfamilyName,
      // textOverflow: "ellipsis",
      // overflow: "hidden",
      // whiteSpace: "nowrap",
    };
    const pricesStyle = {
      marginLeft: "10px",
      fontSize: this.state.fontsizeFoodItem + "px",
      color: this.state.color_textPrice,
      fontFamily: this.state.fontfamilyPrice,
      textTransform: "capitalize",
    };
    const imageVector = {
      height: "100%",
      display: "flex",
      alignContent: "center",
    };

    return (
      <>
        <div className="container_component " style={divStyle}>
          <Button
            variant="primary"
            className="circlebutton"
            onClick={this.handleShowModify}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
          <Button
            className="circlebutton"
            variant="danger"
            onClick={this.handleShowDelete}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          {this.state.multiFoodGroup_items &&
            this.state.multiFoodGroup_items
              .filter(function (value) {
                return value.id_foodgroup !== "";
              })

              .map((foodGroup, i) => {
                return (
                  <div style={containerStyle}>
                    <div style={titlebarStyle}>
                      <div
                        className="icon_container resizeSVG "
                        style={iconContainer}
                      >
                        <div style={imageVector}>
                          {this.svgRender(foodGroup.res.picto)}
                        </div>
                      </div>
                      <div className="text_title" style={titleStyle}>
                        <h1>{foodGroup.res.name}</h1>
                      </div>
                    </div>
                    <div style={foodGroupStyle} className="multiFoodGroupList">
                      {foodGroup.food_items[0] &&
                        foodGroup.food_items
                          .filter(function (value) {
                            return value.active !== false;
                          })
                          .slice(0, this.getmaxFoodItem())
                          .map((foodItem, i) => {
                            return (
                              <div style={rowItemStyle}>
                                <h3 style={nameStyle}>{foodItem.name} </h3>
                                {foodItem.prices === null ? null : (
                                  foodItem.prices[0].value === 0 || foodItem.prices[0].value === null ? null : (
                                  <h3 style={pricesStyle}>
                                    {foodItem.prices[0].value}
                                    {foodItem.prices[0].currency === " " ||
                                    foodItem.prices[0].currency === "EUR" ||
                                    foodItem.prices[0].currency === "Eur" ||
                                    foodItem.prices[0].currency === "eur" ||
                                    foodItem.prices[0].currency === "Euro"
                                      ? "€"
                                      : this.capitalize(
                                          foodItem.prices[0].currency
                                        )}
                                    </h3>)
                                  )}
                              </div>
                            );
                          })}
                    </div>
                  </div>
                );
              })}
        </div>

        {/* MODAL MODIFY */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
           fade={false}
           animation={false}
            className="sideMenu"
            show={this.state.show_modify}
            onHide={this.cancelOnModal}
            backdrop="static"
            keyboard={false}
            scrollable={true}
            backdropClassName="backdrop_sideMenu"
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Multi Food Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {/* FOODGROUP ID*/}
                {/* FOODGROUP ID 1 e 2*/}
                <Row>
                  {/* FOODGROUP ID 1  */}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>1 FoodGroup Id</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.multiFoodGroup_items[0].id_foodgroup}
                            onChange={(e) => {
                              this.handleChangeIDFoodgroup(e, 0);
                            }}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.getFoodItems.bind(this, 0)}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          FoodGroup:
                          <h6
                            className={
                              this.state.multiFoodGroup_items[0]
                                .foodgroup_validation ==
                                "l'iD Food Group don't exist" && "warning"
                            }
                          >
                            {this.state.multiFoodGroup_items[0].foodgroup_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* FOODGROUP ID 2  */}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>2 FoodGroup Id</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.multiFoodGroup_items[1].id_foodgroup}
                            onChange={(e) => {
                              this.handleChangeIDFoodgroup(e, 1);
                            }}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.getFoodItems.bind(this, 1)}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          FoodGroup:
                          <h6
                            className={
                              this.state.multiFoodGroup_items[1]
                                .foodgroup_validation ==
                                "l'iD Food Group don't exist" && "warning"
                            }
                          >
                            {this.state.multiFoodGroup_items[1].foodgroup_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FOODGROUP ID 3 e 4*/}
                <Row>
                  {/* FOODGROUP ID 3  */}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>3 FoodGroup Id</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.multiFoodGroup_items[2].id_foodgroup}
                            onChange={(e) => {
                              this.handleChangeIDFoodgroup(e, 2);
                            }}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.getFoodItems.bind(this, 2)}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          FoodGroup:
                          <h6
                            className={
                              this.state.multiFoodGroup_items[2]
                                .foodgroup_validation ==
                                "l'iD Food Group don't exist" && "warning"
                            }
                          >
                            {this.state.multiFoodGroup_items[2].foodgroup_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* FOODGROUP ID 4  */}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>4 FoodGroup Id</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.multiFoodGroup_items[3].id_foodgroup}
                            onChange={(e) => {
                              this.handleChangeIDFoodgroup(e, 3);
                            }}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.getFoodItems.bind(this, 3)}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          FoodGroup:
                          <h6
                            className={
                              this.state.multiFoodGroup_items[3]
                                .foodgroup_validation ==
                                "l'iD Food Group don't exist" && "warning"
                            }
                          >
                            {this.state.multiFoodGroup_items[3].foodgroup_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FOODGROUP ID 5 e 6*/}
                <Row>
                  {/* FOODGROUP ID 5  */}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>5 FoodGroup Id</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.multiFoodGroup_items[4].id_foodgroup}
                            onChange={(e) => {
                              this.handleChangeIDFoodgroup(e, 4);
                            }}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.getFoodItems.bind(this, 4)}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          FoodGroup:
                          <h6
                            className={
                              this.state.multiFoodGroup_items[4]
                                .foodgroup_validation ==
                                "l'iD Food Group don't exist" && "warning"
                            }
                          >
                            {this.state.multiFoodGroup_items[4].foodgroup_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* FOODGROUP ID 6  */}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>6 FoodGroup Id</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.multiFoodGroup_items[5].id_foodgroup}
                            onChange={(e) => {
                              this.handleChangeIDFoodgroup(e, 5);
                            }}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.getFoodItems.bind(this, 5)}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          FoodGroup:
                          <h6
                            className={
                              this.state.multiFoodGroup_items[5]
                                .foodgroup_validation ==
                                "l'iD Food Group don't exist" && "warning"
                            }
                          >
                            {this.state.multiFoodGroup_items[5].foodgroup_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FOODGROUP ID 7 e 8*/}
                <Row>
                  {/* FOODGROUP ID 7  */}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>7 FoodGroup Id</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.multiFoodGroup_items[6].id_foodgroup}
                            onChange={(e) => {
                              this.handleChangeIDFoodgroup(e, 6);
                            }}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.getFoodItems.bind(this, 6)}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          FoodGroup:
                          <h6
                            className={
                              this.state.multiFoodGroup_items[6]
                                .foodgroup_validation ==
                                "l'iD Food Group don't exist" && "warning"
                            }
                          >
                            {this.state.multiFoodGroup_items[6].foodgroup_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>

                  {/* FOODGROUP ID 8  */}
                  <Col md={6}>
                    <Form.Group>
                      <Form.Label>8 FoodGroup Id</Form.Label>
                      <Col md={12}>
                        <InputGroup>
                          <Form.Control
                            type="number"
                            value={this.state.multiFoodGroup_items[7].id_foodgroup}
                            onChange={(e) => {
                              this.handleChangeIDFoodgroup(e, 7);
                            }}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={this.getFoodItems.bind(this, 7)}
                            >
                              Ok
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                        <Form.Text className="text-muted">
                          FoodGroup:
                          <h6
                            className={
                              this.state.multiFoodGroup_items[7]
                                .foodgroup_validation ==
                                "l'iD Food Group don't exist" && "warning"
                            }
                          >
                            {this.state.multiFoodGroup_items[7].foodgroup_validation}
                          </h6>
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Background color  */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Background</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_listBg}
                          onChange={this.handleChangeColorListBg}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_listBg}
                          onChange={this.handleChangeColorListBg}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_listBg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* BACKGROUND ICON */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color background Icon</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_bgicon}
                          onChange={this.handleChangeColorBgIcon}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_bgicon}
                          onChange={this.handleChangeColorBgIcon}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bgicon}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Color ICON */}

                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Icon</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_icon}
                          onChange={this.handleChangeColorIcon}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_icon}
                          onChange={this.handleChangeColorIcon}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_icon}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Background color Title */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Bar text</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_bg}
                          onChange={this.handleChangeColorBg}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_bg}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Color Title */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Text Title</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_text}
                          onChange={this.handleChangeColorText}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONT SIZE TITLE*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font size Title</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontsize}
                          onChange={this.handleChangeFontSize}
                          min="20"
                          max="35"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontsize}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* FONT FAMILY TITLE*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family Title</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamily}
                          value={this.state.fontfamily}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {this.state.text}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/*  LIST ITEM FOOD GROUP */}
                {/* FONT SIZE  ITEM FOOD GROUP  */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font size Food Item</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.fontsizeFoodItem}
                          onChange={this.handleChangeFontSizeFoodItem}
                          min="20"
                          max="35"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          font-size: {this.state.fontsizeFoodItem}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Color  NAME ITEM FOOD GROUP  */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Name Food Item</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_textName}
                          onChange={this.handleChangeColorTextName}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_textName}
                          onChange={this.handleChangeColorTextName}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_textName}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONT FAMILY  NAME ITEM FOOD GROUP  */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family Name Food Item</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamilyName}
                          value={this.state.fontfamilyName}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {this.state.fontfamilyName}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Color  PRICE ITEM FOOD GROUP  */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Text Price Food Item</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_textPrice}
                          onChange={this.handleChangeColorTextPrice}
                        />
                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_textPrice}
                          onChange={this.handleChangeColorTextPrice}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_textPrice}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* FONT FAMILY  PRICE ITEM FOOD GROUP  */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Font family Price Food Item</Form.Label>
                      <Col md={10}>
                        <Form.Control
                          as="select"
                          onChange={this.handleChangeFontFamilyPrice}
                          value={this.state.fontfamilyPrice}
                        >
                          {listFont.map((font) => {
                            return (
                              <option
                                value={font.font}
                                style={{ fontFamily: font.font }}
                              >
                                {font.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {this.state.fontfamilyPrice}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Color  LINE LIST ITEM FOOD GROUP  */}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Color Line</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="color"
                          value={this.state.color_line}
                          onChange={this.handleChangeColorLine}
                        />

                        <Form.Control
                          className="my-2"
                          type="text"
                          value={this.state.color_line}
                          onChange={this.handleChangeColorLine}
                        />

                        <Form.Text className="text-muted">
                          Color select: {this.state.color_line}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Z-index*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Z-Index</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.zindex_tb}
                          onChange={this.handleChangeIndex}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          z-index: {this.state.zindex_tb}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Curved Edges*/}
                <Row>
                  <Col md={10}>
                    <Form.Group>
                      <Form.Label>Curved Edges Pixels</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          type="number"
                          value={this.state.Curved_tb}
                          onChange={this.handleChangeCurved}
                          min="1"
                          max="999"
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        />
                        <Form.Text className="text-muted">
                          min:1 max:999 <br />
                          Curved Edges Pixels: {this.state.Curved_tb}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.cancelOnModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={this.confirmChangeonModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL DELETE */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            show={this.state.show_delete}
            onHide={this.handleCloseDelete}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Multi food group</Modal.Title>
            </Modal.Header>
            <Modal.Body>wonna delete?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseDelete}>
                Close
              </Button>
              <Button
                onClick={() => this.props.onRemoveItem(this.props.item.i)}
                variant="danger"
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default MultiFoodGroup_Land;
