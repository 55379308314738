import React from "react";

class Background extends React.Component {
  borderRadiusContainer() {
    if (this.props.item.borderRadius===undefined)
      {
        this.props.item.borderRadius=0
       }
      let borderRadiusContainer = this.props.item.borderRadius
        return borderRadiusContainer;
  }
  render() {
    const divStyle = {
      backgroundColor: this.props.item.backgroundColor,
      width: "100%",
      height: "100%",
      transition: "all 250ms ease",
      backgroundImage: `url(${this.props.item.backgroundImage})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      borderRadius:this.borderRadiusContainer() +"px",
    };

    return (
      <>
        <div style={divStyle}></div>
      </>
    );
  }
}

export default Background;
