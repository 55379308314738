import React from 'react';

class ToolboxItem extends React.Component {

  showLongName(shortName) {
    switch (shortName) {
      case 'BAC':
        return "Background";

      case 'BAR':
        return "NavBar";

      case 'PRO':
        return "Promotion Horizontal";

      case 'PRV':
        return "Promotion Vertical";

      case 'LOG':
        return "Logo";

      case 'IMG':
        return "Image";

      case 'TIT':
        return "Title Bar";

      case 'MLH':
        return "Line Horizontal";

      case 'MLV':
        return "Line Vertical";

      case 'DAT':
        return "Data";

      case 'SAN':
        return "Saint";

      case 'WEA':
        return "Weather";

      case 'FLL':
        return "Fluidity Line";

      case 'FLR':
        return "Fluidity Rectangle";

      case 'FIM':
        return "Fluidity List";

      case 'DSM':
        return "Dispatch";

      case 'FGL':
        return "Food Group";
      case 'FGP':
        return "Food Group";
      case 'TTX':
        return "Text";

      case 'MFG':
        return "Multi Food Group";

      default:
        return shortName;
    }

  }
  render() {
    return (
      <div
        className="toolbox__items__item"
        onClick={this.props.onTakeItem.bind(undefined, this.props.item)}
      >
        {this.showLongName(this.props.item.i)}
      </div>
    );
  }
}

export default ToolboxItem;