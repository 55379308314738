import Item from './Item';

class ImageModel extends Item {

    constructor(i, x, maxH, maxW, minH, minW) {

        super(i, x, maxH, maxW, minH, minW);
        this.backgroundImage = "";
        this.backgroundColor = "#F0F0F0";
    }

}

export default ImageModel;