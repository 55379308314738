import React from "react";
import Header from "../components/header/Header";
import {  Image } from "react-bootstrap";
import logoimok from "../image/logowhite.png";
import { getDisplays, getRegionByID } from "../api/Api";
import {Redirect} from "react-router-dom";
import { saveToLS } from "../utils/Utils";


class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      template_display:null,
      redirectForbidden: false
    }
  }

  componentDidMount() {
    if (process.env.REACT_APP_DEBUG === "true")
      {
  //do nothing
      }else{
      if (document.cookie.indexOf("imokb=") == -1) {
        this.setState({ redirectForbidden: true });
      }}
 
  }



  onSelectTemplate= (templateDisplay) => {
    this.setState({ template_display: templateDisplay}); 
    saveToLS("layout", templateDisplay[2].layout);
    saveToLS("items", templateDisplay[1].items);
   console.log("template: "+templateDisplay)
  }


  render() {
    const {redirectForbidden} = this.state
    if (redirectForbidden) {
      return <Redirect to='/'/>;
    }

    return (

      <div className="h-100 d-flex flex-column">
        <Header preview="false" save="false"  onSelectTemplate={this.onSelectTemplate} />
          
            <div
              className=" d-flex align-items-center flex-column flex-grow-1 "
            >
              <div className="aldebran_title text-center m-auto pb-5">
                <h2>Aldebaran</h2>
                <h5>screen editor</h5>
                <h1>Welcome</h1>
                <h6>Press "file" to start</h6>
              </div>
            </div>
            <div
              className="d-flex align-items-center flex-column pt-5 pb-4 "
            >
           <Image src={logoimok} fluid   className=""/>
           </div>
      </div>
    );
  }
}

export default Home;
