import React from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Image,
  Modal,
  ListGroup,
  Toast,
  Row,
  Col,
  Form,
  FormControl,
  Pagination,
} from "react-bootstrap";
import logoimok from "../../image/logowhite.png";
import { saveDisplay, getDisplays, loadDisplay } from "../../api/Api";
import { saveToLS } from "../../utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faCheck,
  faExclamationTriangle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_save: false,
      show_newtemplate: false,
      show_changetemplate: false,
      loadtoast: false,
      toastsaved: false,
      toasterror: false,
      load_display: [],
      active_pagesave: 1,
      active_pageload: 1,
      displayforpage: 12,
      active_idDisplaySave: null,
      active_idDisplayLoad: null,
      template_display: "",
      next_actsave: 0,
      next_actload: 0,
      currentDisplayPage: [],
      prev_actsave: 0,
      prev_actload: 0,
      pageNumbers: [],
    };
  }

  //Load JSON DISPLAY
  handleLoadDisplay = (id) => {
    this.setState({ active_idDisplayLoad: Number(id) });
  };

  LoadDisplayTemplate = () => {
    loadDisplay(this.state.active_idDisplayLoad).then((res) => {
      if (res) {
        this.setState({ template_display: res });
        if (res[0].orientation == "landscape") {
          this.props.onSelectTemplate(this.state.template_display);
          window.location = "/editorlandscape";
        } else if (res[0].orientation == "portrait") {
          this.props.onSelectTemplate(this.state.template_display);
          window.location = "editorportrait";
        }
      } else {
      }
    });

    this.setState({ show_changetemplate: false });
  };

  //GET DISPLAY
  handleGetDsiplay = () => {
    getDisplays().then((res) => {
      if (res) {
        this.setState({ load_display: res });
        this.CreatePagination();
        this.CreatePaginationLoadTemplate();
      } else {
      }
    });
  };

  //GET ID DISPLAY to SAVE
  handleIDToSave = (id) => {
    this.setState({ active_idDisplaySave: Number(id) });
  };
  //Accept SAVE
  handleAcceptSave = () => {
    let layoutToSave = [];

    layoutToSave.unshift({ orientation: localStorage.getItem("orientation") });
    layoutToSave.push({ items: this.props.items });
    console.log(this.props.items )
    layoutToSave.push({ layout: this.props.layout });

    saveDisplay(layoutToSave, this.state.active_idDisplaySave).then((res) => {
      if (res === true) {
        this.setState({ loadtoast: false });
        this.setState({ toastsaved: true });
      } else {
        this.setState({ toasterror: true });
        this.setState({ loadtoast: false });
      }
    });
    this.setState({ show_save: false });
    this.setState({ loadtoast: true });
  };

  handlePreview = () => {
    saveToLS("layout", this.props.layout);
    saveToLS("items", this.props.items);
  };

  //MODAL

  //MODAL CHANGE Template
  //Open
  handleShowChangeTemplate = () => {
    this.setState({ show_changetemplate: true });
    this.handleGetDsiplay();
  };
  //Close
  handleCloseChangeTemplate = () => {
    this.setState({ show_changetemplate: false });
    this.setState({ currentDisplayPage: [] });
    this.setState({ active_pageload: 1 });
  };

  //MODAL SAVE
  //Close
  handleCloseSave = () => {
    this.setState({ show_save: false });
    this.setState({ currentDisplayPage: [] });
    this.setState({ active_pagesave: 1 });
  };
  //Open
  handleShowSave = () => {
    this.handleGetDsiplay();
    this.setState({ show_save: true });
  };

  //PAGINATION
  // Pagination LOAD TEMPLATE MODAL
  CreatePaginationLoadTemplate = () => {
    const { load_display, active_pageload, displayforpage } = this.state;

    const indexOfLastdisplay = active_pageload * displayforpage;
    const indexOfFirstdisplay = indexOfLastdisplay - displayforpage;
    const currentDisplayPage = load_display.slice(
      indexOfFirstdisplay,
      indexOfLastdisplay
    );

    this.setState({ currentDisplayPage: currentDisplayPage });

    let next_actload =
      active_pageload <= 2
        ? 5
        : Math.ceil(load_display.length / displayforpage) - active_pageload <= 3
        ? Math.ceil(load_display.length / displayforpage)
        : active_pageload + 3;

    this.setState({ next_actload: next_actload });
    let prev_actload =
      active_pageload <= 2
        ? 0
        : Math.ceil(load_display.length / displayforpage) - active_pageload <= 2
        ? Math.ceil(load_display.length / displayforpage) - 5
        : active_pageload - 2;
    this.setState({ prev_actload: prev_actload });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(load_display.length / displayforpage); i++) {
      pageNumbers.push(i);
    }
    this.setState({ pageNumbers: pageNumbers });
  };
  // Pagination Load Template Modal Buttons
  handleLastPaginationLoadTemplate = () => {
    const lastpage = this.state.load_display.length / this.state.displayforpage;
    this.setState(
      {
        active_pageload: Math.ceil(lastpage),
      },
      () => {
        this.CreatePaginationLoadTemplate();
      }
    );
  };
  handlePaginationLoadTemplate = (event) => {
    this.setState({ active_pageload: Number(event.target.id) }, () => {
      this.CreatePaginationLoadTemplate();
    });
  };
  handleFirstPaginationLoadTemplate = () => {
    this.setState({ active_pageload: 1 }, () => {
      this.CreatePaginationLoadTemplate();
    });
  };
  // Pagination SAVE MODAL
  CreatePagination = () => {
    const { load_display, active_pagesave, displayforpage } = this.state;

    const indexOfLastdisplay = active_pagesave * displayforpage;
    const indexOfFirstdisplay = indexOfLastdisplay - displayforpage;
    const currentDisplayPage = load_display.slice(
      indexOfFirstdisplay,
      indexOfLastdisplay
    );

    this.setState({ currentDisplayPage: currentDisplayPage });

    let next_actsave =
      active_pagesave <= 2
        ? 5
        : Math.ceil(load_display.length / displayforpage) - active_pagesave <= 3
        ? Math.ceil(load_display.length / displayforpage)
        : active_pagesave + 3;

    this.setState({ next_actsave: next_actsave });
    let prev_actsave =
      active_pagesave <= 2
        ? 0
        : Math.ceil(load_display.length / displayforpage) - active_pagesave <= 2
        ? Math.ceil(load_display.length / displayforpage) - 5
        : active_pagesave - 2;
    this.setState({ prev_actsave: prev_actsave });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(load_display.length / displayforpage); i++) {
      pageNumbers.push(i);
    }
    this.setState({ pageNumbers: pageNumbers });
  };
  // Pagination SAVE Modal Buttons
  handleFirstPagination = () => {
    this.setState({ active_pagesave: 1 }, () => {
      this.CreatePagination();
    });
  };
  handleLastPagination = () => {
    const lastpage = this.state.load_display.length / this.state.displayforpage;
    this.setState(
      {
        active_pagesave: Math.ceil(lastpage),
      },
      () => {
        this.CreatePagination();
      }
    );
  };
  handlePagination = (event) => {
    this.setState({ active_pagesave: Number(event.target.id) }, () => {
      this.CreatePagination();
    });
  };

  //TOAST
  //Close Toast
  handleShow = () => {
    this.setState({ toasterror: false });
    this.setState({ toastsaved: false });
  };

  handleNewEditor = () => {
    localStorage.removeItem("layout");
    localStorage.removeItem("items");
  };

  render() {
    const { preview, save } = this.props;
    const {
      pageNumbers,
      next_actsave,
      next_actload,
      prev_actsave,
      prev_actload,
      currentDisplayPage,
    } = this.state;

    return (
      <>
        <Navbar expand="md" className="bg_dark newshadow navbar-dark">
          <Navbar.Brand href="../home">
            <Image src={logoimok} fluid width="120" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown
                title="File"
                id="basic-nav-dropdown"
                className="bg_dark"
              >
                <NavDropdown.Item
                  onClick={this.handleNewEditor}
                  href="./editorlandscape"
                >
                  New Landscape
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={this.handleNewEditor}
                  href="./editorportrait"
                >
                  New Portrait
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.handleShowChangeTemplate}>
                  Use template
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                href="#home"
                disabled={save}
                onClick={this.handleShowSave}
              >
                Save
              </Nav.Link>
              <Nav.Link
                onClick={this.handlePreview}
                href={this.props.previewLink}
                target="_blank"
                disabled={preview}
              >
                Preview
              </Nav.Link>
            </Nav>

            <Button
              variant="outline-danger"
              href="./"
              onClick={() =>
                (document.cookie =
                  "imokb" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;")
              }
            >
              LOG OUT
            </Button>
          </Navbar.Collapse>
        </Navbar>

        {/* MODAL Save */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            scrollable
            centered
            show={this.state.show_save}
            onHide={this.handleCloseSave}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>Save</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="bg_dark_outline p-3">
                <Col md={12}>
                  <p>Select the display where you wonna save</p>
                  <h5>
                    <strong>
                      Display id: {this.state.active_idDisplaySave}
                    </strong>
                  </h5>
                </Col>
              </Row>
              {/*   <Row className=" py-3">
                <Col md={12}>
                  <Form inline>
                    <FormControl
                      type="text"
                      placeholder="Search"
                      className="mr-sm-2"
                      onChange={this.handleOnSearchChange}
                    />
                    <Button variant="primary">Search</Button>
                  </Form>
                </Col>
              </Row> */}
              <ListGroup variant="flush">
                {currentDisplayPage[1] ? (
                  currentDisplayPage.map((obj, idx) => {
                    return (
                      <ListGroup.Item
                        action
                        key={idx}
                        id={obj.id}
                        onClick={this.handleIDToSave.bind(this, obj.id)}
                        active={this.state.active_idDisplaySave === obj.id}
                      >
                        <strong>id: {obj.id}</strong> {obj.name}
                      </ListGroup.Item>
                    );
                  })
                ) : (
                  <Col>
                    <h5 className="text-center">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        pulse
                        className="mr-2"
                      />
                      <strong className="mr-auto">Loading...</strong>
                    </h5>
                  </Col>
                )}
              </ListGroup>
            </Modal.Body>

            <Modal.Footer>
              <Col md={12}>
                <Pagination size="sm" className="justify-content-center">
                  <Pagination.First onClick={this.handleFirstPagination} />

                  {pageNumbers
                    .slice(prev_actsave, next_actsave)
                    .map((number) => {
                      return (
                        <Pagination.Item
                          active={this.state.active_pagesave === number}
                          key={number}
                          id={number}
                          onClick={this.handlePagination}
                        >
                          {number}
                        </Pagination.Item>
                      );
                    })}

                  <Pagination.Last onClick={this.handleLastPagination} />
                </Pagination>
              </Col>
              <Button variant="secondary" onClick={this.handleCloseSave}>
                Close
              </Button>
              <Button onClick={this.handleAcceptSave} variant="primary">
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* MODAL Change template */}
        <div onMouseDown={(e) => e.stopPropagation()}>
          <Modal
            centered
            show={this.state.show_changetemplate}
            onHide={this.handleCloseChangeTemplate}
            className="modal_dark"
            backdropClassName="backdrop_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Load</h3>
                <h4>Display id: {this.state.active_idDisplayLoad}</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup variant="flush">
                {currentDisplayPage[0] ? (
                  currentDisplayPage.map((obj, idx) => {
                    return (
                      <ListGroup.Item
                        action
                        key={idx}
                        id={obj.id}
                        onClick={this.handleLoadDisplay.bind(this, obj.id)}
                        active={this.state.active_idDisplayLoad === obj.id}
                      >
                        <strong>id: {obj.id}</strong> {obj.name}
                      </ListGroup.Item>
                    );
                  })
                ) : (
                  <Col>
                    <h5 className="text-center">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        pulse
                        className="mr-2"
                      />
                      <strong className="mr-auto">Loading...</strong>
                    </h5>
                  </Col>
                )}
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
              <Col md={12}>
                <Pagination size="sm" className="justify-content-center">
                  <Pagination.First
                    onClick={this.handleFirstPaginationLoadTemplate}
                  />

                  {pageNumbers
                    .slice(prev_actload, next_actload)
                    .map((number) => {
                      return (
                        <Pagination.Item
                          active={this.state.active_pageload === number}
                          key={number}
                          id={number}
                          onClick={this.handlePaginationLoadTemplate}
                        >
                          {number}
                        </Pagination.Item>
                      );
                    })}

                  <Pagination.Last
                    onClick={this.handleLastPaginationLoadTemplate}
                  />
                </Pagination>
              </Col>
              <Button
                variant="secondary"
                onClick={this.handleCloseChangeTemplate}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={this.LoadDisplayTemplate}>
                Select
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        {/* Toast loading */}
        <div id="SavingToast">
          <Col xs={12}>
            <Toast show={this.state.loadtoast}>
              <Toast.Body>
                <FontAwesomeIcon icon={faSpinner} pulse className="mr-2" />
                <strong className="mr-auto">Saving...</strong>
              </Toast.Body>
            </Toast>
          </Col>
        </div>
        {/* Toast Saved */}
        <div id="SavedToast">
          <Row>
            <Col xs={12}>
              <Toast
                show={this.state.toastsaved}
                delay={3000}
                onClose={this.handleShow}
                autohide
                className="bg_dark"
              >
                <Toast.Header className="bg_dark text_accent">
                  <FontAwesomeIcon icon={faCheck} className="mr-2 " />
                  <strong className="mr-auto">Saved</strong>
                </Toast.Header>
                <Toast.Body className="bg_accent text_white">
                  The file has been saved!
                </Toast.Body>
              </Toast>
            </Col>
          </Row>
        </div>

        {/* Toast Error */}
        <div id="ErrorToast">
          <Row>
            <Col xs={12}>
              <Toast
                show={this.state.toasterror}
                delay={3000}
                onClose={this.handleShow}
                autohide
                className="bg_dark"
              >
                <Toast.Header className="bg_dark text_error">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-2 "
                  />
                  <strong className="mr-auto">Error</strong>
                </Toast.Header>
                <Toast.Body className="bg_error text_white">
                  Ops...An error has occurred.
                </Toast.Body>
              </Toast>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Header;
