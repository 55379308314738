import Item from './Item';

class BarModel extends Item {

    constructor(i, x, maxH, maxW, minH, minW) {

        super(i, x, maxH, maxW, minH, minW);
        this.background = "#F0F0F0";
        this.alpha = "0.8";
    }

}

export default BarModel;