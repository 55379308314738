import React from 'react';
import Signin from '../components/Login/Signin';


class Login extends React.Component {

    render() {
        return(
            <>
               <Signin />
           </>
        )
    }
}

export default Login;