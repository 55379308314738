import React from "react";
import Header from "../components/header/Header";
import { Image, Button, Form, FormControl, Table, Container, Row,Col } from "react-bootstrap";
import logoimok from "../image/logowhite.png";
import { getDisplays } from "../api/Api";

class SelectScreenType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_screen: 47,
    };
  }

  render() {
    return (
      <div className="h-100 d-flex flex-column">
        <Header preview="false" save="false" id_screen={this.state.id_screen} />

        <Container>
          <Row>
              <Col>
            <h2>SELECT SCREEN</h2>
            </Col>
            <Col>
            <Form inline>
              <FormControl
                type="text"
                placeholder="Search"
                className="mr-sm-2"
              />
              <Button variant="primary">Search</Button>
            </Form>
            </Col>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>#</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Username</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td colSpan="2">Larry the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </Table>

            <Button variant="outline-danger" href="./editorlandscape">
              Go to editor
            </Button>
          </Row>
        </Container>
        <div className="  d-flex align-items-center flex-column pt-5 pb-4 ">
          <Image src={logoimok} fluid className="" />
        </div>
      </div>
    );
  }
}

export default SelectScreenType;
