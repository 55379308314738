import Item from './Item';

class FluidityLineModel extends Item {

    constructor(i, x, maxH, maxW, minH, minW) {

        super(i, x, maxH, maxW, minH, minW);
        this.background = "#FFFFFF";
        this.zona = "";
        this.zoneName = "ZONE NAME";
        this.valueLive = 0;
        this.capacity = 100;
        this.zoneValidation = "";
        this.textColor = "#999999";
        this.textFont = "Montserrat";
        this.textSize = 22;
    }

}

export default FluidityLineModel;