import React from "react";
import ToolboxItem from "./ToolboxItem";
import { Button, Modal, Navbar, Container } from 'react-bootstrap'
class Toolbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_toolbox: false,
    };
  }

  //Show/hide ToolBox
  //Close
  handleCloseToolBox = () => {
    this.setState({ show_toolbox: false });
  };
  //Open
  handleShowToolBox = () => {
    this.setState({ show_toolbox: true });
  };
  render() {
    return (
      <>
        <Navbar>
          <Container fluid className="p-1" >
            <Button variant="primary" onClick={this.handleShowToolBox}>
              +
            </Button>
            <p className="m-3">Add Component</p>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">

            </Navbar.Collapse>
          </Container>
        </Navbar>

        {/* MODAL MODIFY */}
        <Modal
          fade={false}
          animation={false}
          show={this.state.show_toolbox}
          onHide={this.handleCloseToolBox}
          keyboard={false}
          scrollable={true}
          className="sideToolbox"
          backdropClassName="backdrop_sideToolbox"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Component</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="toolbox">
              <div className="toolbox_items" onClick={this.handleCloseToolBox}>
                {this.props.items.map((item) => (
                  <ToolboxItem
                    key={item.i}
                    item={item}
                    onTakeItem={this.props.onTakeItem}
                  />
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseToolBox}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Toolbox;
