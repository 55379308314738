import Item from './Item';

class DispatchModel extends Item {

    constructor(i, x, maxH, maxW, minH, minW, widhtSize, heightSize, background) {

        super(i, x, maxH, maxW, minH, minW);
        this.background = background;
        this.widhtSize = widhtSize;
        this.heightSize = heightSize;
        this.w = widhtSize;
        this.h = heightSize;
        this.restaurantID = 0;
        this.textDispatch = "";
    }

}

export default DispatchModel;